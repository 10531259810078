<section class="funfacts-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-user-tie"></i>
                    <h4><span style="color: black;">Empleados</span></h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-briefcase"></i>
                    <h4><span style="color: black;">Empleadores</span></h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fa fa-building"></i>
                    <h4><span style="color: black;">Empresas</span></h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-user"></i>
                    <h4><span style="color: black;">Independientes</span></h4>
                </div>
            </div>
        </div>
    </div>
</section>