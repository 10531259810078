<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Quienes <span>Somos</span></h2>
            <p>Un equipo de profesionales apasionados por el bienestar integral de los empleados, 
                entendiendo que un ambiente de trabajo saludable es fundamental para el éxito personal y empresarial
            </p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>Servicio</h3>
                    <p>Nos dedicamos a servir a nuestros clientes con excelencia y dedicación. 
                        Buscamos constantemente maneras de agregar valor y mejorar su experiencia con nuestros servicios</p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-handshake"></i>
                    <h3>Compromiso</h3>
                    <p>Nos dedicamos plenamente a la mejora del bienestar de los empleados y al éxito de nuestras 
                        empresas clientes. Nuestro compromiso se refleja en la calidad de los servicios que ofrecemos
                        y en nuestro enfoque personalizado</p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa fa-puzzle-piece"></i>
                    <h3>Integridad</h3>
                    <p>Actuamos con honestidad, transparencia y ética en todas nuestras interacciones. La confianza de nuestros clientes 
                        y colaboradores es fundamental, y la mantenemos a través de la coherencia entre nuestras palabras y acciones.</p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa fa-user-secret"></i>
                    <h3>Confidencialidad</h3>
                    <p> Respetamos y protegemos la privacidad y la confidencialidad de la información de nuestros clientes y empleados.
                         Tratamos todos los datos con el máximo cuidado y responsabilidad</p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa fa-search"></i>
                    <h3>transparencia</h3>
                    <p>: Operamos con claridad y apertura, asegurando que nuestros procesos, 
                        decisiones y resultados sean accesibles y comprensibles para todos nuestros clientes</p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa fa-heart"></i>
                    <h3>Pasión</h3>
                    <p>Nos apasiona lo que hacemos y esa energía se refleja en nuestro trabajo diario. 
                        Creemos en el impacto positivo del bienestar laboral y nos dedicamos con entusiasmo a nuestra misión</p>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</div>