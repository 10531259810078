import { Component } from '@angular/core';

@Component({
  selector: 'app-password-manager',
  templateUrl: './password-manager.component.html',
  styleUrl: './password-manager.component.scss'
})
export class PasswordManagerComponent {

}
