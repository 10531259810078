<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2> <span>Objetivo</span></h2>
            <p>Brindar servicios empresariales de excelencia, sustentados en el profesionalismo, 
                el compromiso, la ética y la constante innovación, con el fin de satisfacer las necesidades 
                y superar las expectativas de nuestros clientes</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2><span>Misión</span></h2>
                        <p>Promover la salud, el bienestar y la seguridad en los entornos laborales mediante un acompañamiento,
                            asesoramiento profesional y especializado a independientes, empleadores, empresas y empleados, 
                            lo que permitirá ejecutar programas que creen entornos laborales seguros, saludables y motivadores, 
                            contribuyendo así al éxito sostenible de las organizaciones</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about1.jpg" alt="about">
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
        </div>
   <br> <br>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12"> 
                <div class="about-content">
                    <div class="section-title">
                        <h2><span>Visión</span></h2>
                        <p>Ser la opción preferida y confiable en la promoción de la salud, el bienestar y la seguridad en los entornos laborales, 
                            siendo reconocidos por nuestra integridad, profesionalismo y compromiso con la mejora continua</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about2.jpg" alt="about">
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>