<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>Meet Addax</h4>
            <h2>Our Creative <span>Team</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="team-slides">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Camila Alzate</h3>
                            <span class="post">Coordinadora Comercial</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Yesica Marín</h3>
                            <span class="post">Coodinadora Operativa</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
               
            
            </owl-carousel-o>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>