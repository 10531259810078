<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/">Sobiem</a></h3>
        <ul>
            <li><a href="https://wa.me/573013823341?text=Estoy interesad@ en cotizar el servicio de: " target="_blank" class="fab fa-whatsapp"></a></li>
            <li><a href="https://instagram.com/sobiem_sas" target="_blank" class="fab fa-instagram"></a></li>
        </ul>
        <p>Copyright <i class="far fa-copyright"></i> 2024 Todos los derechos reservados</p>
    </div>
</footer>

<div class='whatsapp'>
    <div class="separator" style="clear: both; text-align: left;">
    </div>
    <div class="separator" style="clear: both; text-align: center;">
    </div>
    <div class="separator" style="clear: both; text-align: center;">
    </div>
    <div class="separator" style="clear: both; text-align: left;">
        <a href="https://wa.me/573013823341?text=Estoy interesad@ en cotizar el servicio de: " style="clear: left; float: left; margin-bottom: 1em; margin-right: 5px;" target="_blank"><img border="0" data-original-height="59" data-original-width="59" src="../../../../assets/img/btn_whatsapp.png" /></a>
    </div>
    <div class="separator" style="clear: both; text-align: left;">
        <b class="small-text">Portafolio</b>
        <a href="https://firebasestorage.googleapis.com/v0/b/sobiem.appspot.com/o/portafolio.pdf?alt=media&token=b5db09c5-22ab-4fe6-837e-e00f6a2d54bb" style="clear: left; float: left; margin-bottom: 1em; margin-right: 5px;" target="_blank"><img border="0" data-original-height="59" data-original-width="59" src="../../../../assets/img/portafolio.png" /></a> 
    </div>

    

<app-demo-sidebar></app-demo-sidebar>

<ngx-scrolltop></ngx-scrolltop>