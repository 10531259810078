<!--Main Navigation-->
<header>
    <!-- Sidebar -->
    <nav id="sidebarMenu" class="collapse d-lg-block sidebar collapse bg-white">
      <div class="position-sticky">
        <div class="list-group list-group-flush mx-3 mt-4">
         
          <a class="list-group-item list-group-item-action py-2 ripple active">
            <i class="fas fa-plus fa-fw me-3"></i><span >Nuevo Usuario</span>
          </a>
  
          <a class="list-group-item list-group-item-action py-2 ripple" aria-current="true">
            <i class="fas fa-file-excel fa-fw me-3" ></i><span>Exportar a Excel</span>
          </a>
         
  
          <a class="list-group-item list-group-item-action py-2 ripple" aria-current="true">
            <i class="fa fa-key fa-fw me-3"></i><span >Aministración Contraseñas</span>
          </a>
          
          <a  class="list-group-item list-group-item-action py-2 ripple"><i
              class="fa fa-sign-out fa-fw me-3" ></i><span >Logout</span></a>
        </div>
      </div>
    </nav>
    <!-- Sidebar -->
  
    <!-- Navbar -->
    <nav id="main-navbar" class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
      <!-- Container wrapper -->
      <div class="container-fluid">
        <!-- Toggle button -->
        <button data-mdb-button-init class="navbar-toggler" type="button" data-mdb-collapse-init data-mdb-target="#sidebarMenu"
          aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fas fa-bars"></i>
        </button>
  
        <!-- Brand -->
        <a class="navbar-brand" href="#">
          <img src="assets/img/logo.png" height="50" alt="Sobiem"
            loading="lazy" />
        </a>
              <!-- Search form -->
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-auto">
                  <form class="d-none d-md-flex input-group w-auto my-auto buscador">
                    <span class="input-group-text border-0"><i class="fas fa-search"></i></span>
                    <div class="search-bar">
                      <input type="text" class="form-control rounded" placeholder="Buscar..." style="min-width: 225px;">
                    </div>                  
                  </form>
                </div>
              </div>
            </div> 
  
        <!-- Right links -->
        <ul class="navbar-nav ms-auto d-flex flex-row">
          <!-- Icon -->
          <li class="nav-item">
            <a class="nav-link me-3 me-lg-0" href="#">
              <i class="fa fa-sign-out"></i>
            </a>
          </li>
          <!-- Icon -->
          <li class="nav-item me-3 me-lg-0">
            <a class="nav-link" href="#">
              <i class="fas fa-file-excel"></i>
            </a>
          </li>
  
          <li class="nav-item me-3 me-lg-0">
            <a class="nav-link" href="#">
              <i class="fas fa-plus"></i>
            </a>
          </li>
        </ul>
      </div>
      <!-- Container wrapper -->
    </nav>
    <!-- Navbar -->
  </header>
  <!--Main Navigation-->
  <br><br>
  <!--Main layout-->
  <main style="margin-top: 58px;">
    <div class="container pt-4">
    
      <!-- The Modal -->
      <div class="modal" id="myModal" [style.display]="showModal ? 'block' : 'none'">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- Modal Header --> 
            <div class="modal-header">
                  <div class="newsletter">
                        
                  </div>      
          </div>
        </div>
      </div>
    </div>
    </div>
  </main>
  <!--Main layout-->