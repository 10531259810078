import { Component, ElementRef, ViewChild } from '@angular/core';
import { ServicesComponent } from '../common/services/services.component';

declare var bootstrap: any; 

@Component({
  selector: 'app-modal-usuarios',
  templateUrl: './modal-usuarios.component.html',
  styleUrls: ['./modal-usuarios.component.scss']
})
export class ModalUsuariosComponent {
  @ViewChild('usuarioModal', { static: false }) usuarioModal!: ElementRef;


  usuarios: any[] = [];
  nuevoUsuario = { nombre: '', entidad: '', usuario: '', contrasena: '' };
  editando = false;
  idUsuarioEditar: string | null = null;

  constructor(private services: ServicesComponent) {}

  ngOnInit() {
    this.services.getUsersPassword().subscribe(users => {
      this.usuarios = users;
    });
  }

  mostrarModal() {
    if (this.usuarioModal) {
      const modal = new bootstrap.Modal(this.usuarioModal.nativeElement);
      modal.show();
    }
  }
  

  cerrarModal() {
    const modal = bootstrap.Modal.getInstance(this.usuarioModal.nativeElement);
    modal.hide();
  }

  guardarUsuario() {
    if (this.editando && this.idUsuarioEditar) {
      this.services.updateUser(this.idUsuarioEditar, this.nuevoUsuario)
        .then(() => this.cerrarModal());
    } else {
      this.services.addUser(this.nuevoUsuario)
        .then(() => this.cerrarModal());
    }
    this.cancelarEdicion();
  }

  editarUsuario(usuario: any) {
    this.nuevoUsuario = { ...usuario };
    this.editando = true;
    this.idUsuarioEditar = usuario.id;
    this.mostrarModal();
  }

  eliminarUsuario(id: string) {
    this.services.deleteUser(id);
  }

  cancelarEdicion() {
    this.nuevoUsuario = { nombre: '', entidad: '', usuario: '', contrasena: '' };
    this.editando = false;
    this.idUsuarioEditar = null;
  }
}
