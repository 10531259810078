<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/about-strategy.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h2> <span>Visión</span></h2>
                        <p>Ser la opción preferida y confiable en la promoción de la salud, el bienestar y la seguridad en los entornos laborales, 
                        siendo reconocidos por nuestra integridad, profesionalismo y compromiso con la mejora continua</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>