import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { SigninComponent } from './components/common/authentication/signin/signin.component';
import { ListMerchantComponent } from './components/list-merchant/list-merchant.component';
import { canActivate, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { PasswordManagerComponent } from './components/common/password-manager/password-manager.component';


const routes: Routes = [
    {path: '', component: HomeOneComponent},
    { path: 'signin', component: SigninComponent },
    { path: 'home', component: HomeOneComponent },
    { path: 'sidebar', component: SidebarComponent },
    { path: 'admin-password', component: PasswordManagerComponent },
    {
        path: 'list-merchant',
        component: ListMerchantComponent,
        ...canActivate(() => redirectUnauthorizedTo(['/home']))
    },
    // {
    //     path: 'password-manager',
    //     component: ListMerchantComponent,
    //     ...canActivate(() => redirectUnauthorizedTo(['/home']))
    //   },
    // Here add new pages component
    { path: '**', redirectTo: 'home', pathMatch: 'full' },
    // {path: '**', component: HomeOneComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }


