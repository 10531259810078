<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2><span>Contáctanos</span></h2>
            <p>¡Conéctate con nosotros en nuestras redes sociales para estar al tanto de nuestras 
                últimas novedades y promociones! Puedes contactarnos a través de</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <a target="_blank" href="https://instagram.com/sobiem_sas"><img src="assets/img/instagram.jpg" alt="instagram"></a>
                    <a target="_blank" href="https://wa.me/573013823341?text=Estoy interesad@ en cotizar el servicio de: "><img src="assets/img/whatsapp.jpg" alt="whatsapp"></a>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">                
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>Ubicación</span>Medellín-Antioquia</li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:sobiemsas&#64;gmail.com"><span>Email</span>sobiemsas&#64;gmail.com</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:573013823341"><span>Celular </span>3013823341</a></li>
                               
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>