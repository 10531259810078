<div #usuarioModal class="modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Administración de Usuarios</h5>
          <button type="button" class="btn-close" (click)="cerrarModal()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!-- Contenido del modal -->
          <input type="text" class="form-control" [(ngModel)]="nuevoUsuario.nombre" placeholder="Nombre">
          <input type="text" class="form-control" [(ngModel)]="nuevoUsuario.entidad" placeholder="Entidad">
          <input type="text" class="form-control" [(ngModel)]="nuevoUsuario.usuario" placeholder="Usuario">
          <input type="text" class="form-control" [(ngModel)]="nuevoUsuario.contrasena" placeholder="Contraseña">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="cerrarModal()">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="guardarUsuario()">Guardar</button>
        </div>
      </div>
    </div>
  </div>
  