import { Component, OnInit } from '@angular/core';
import { Firestore, collection, addDoc, collectionData, query, where, getDocs, onSnapshot, QuerySnapshot, doc, DocumentSnapshot, orderBy, deleteDoc, CollectionReference, QueryDocumentSnapshot, getDoc, updateDoc } from '@angular/fire/firestore';
import ProspectUsers from '../interfaces/general.interface';
import { Observable } from 'rxjs';
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, signInWithPopup, GoogleAuthProvider } from '@angular/fire/auth';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})


export class ServicesComponent implements OnInit {

  constructor(private firestore: Firestore, private auth: Auth) {}

  addMerchant(prospect: ProspectUsers){
    console.log('se metio aca cuadno le doy guardar')
    const prospectRef = collection(this.firestore, 'merchant');
    return addDoc(prospectRef, prospect);
  }

  async getEmailMerchant(email: string): Promise<string[]> {
    const prospectRef = collection(this.firestore, "merchant");
    const q = query(prospectRef, where("email", "==", email));

    const querySnapshot = await getDocs(q);
    const emails: string[] = [];

    querySnapshot.forEach(doc => {
      emails.push(doc.data().email);
    });
    return emails;
  }

  getUsers(id:string): Observable<ProspectUsers[]> {
    const prospectRef = collection(this.firestore, 'merchant');
    return collectionData(prospectRef, { idField: 'email' }) as Observable<ProspectUsers[]>;
  }


  async login({ email, password }: any) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }



  getMerchants(): Observable<any[]> {
    return new Observable<any[]>((subscriber) => {
      const colRef = collection(this.firestore, 'merchant');
      const unsubscribe = onSnapshot(colRef, (snapshot: QuerySnapshot<any>) => {
        const merchants: any[] = [];
        snapshot.docs.forEach((doc) => {
          merchants.push({ ...doc.data(), id: doc.id });
        });
        subscriber.next(merchants);
      }, (error) => {
        subscriber.error(error);
      });

      // Return the unsubscribe function to clean up the listener when the observable is unsubscribed
      return () => unsubscribe();
    });
  }

  async dropMerchant(id: string) {
    await deleteDoc(
      doc(this.firestore, "merchant", id)
    );
  }

  async updateMerchant(id: string, data: any): Promise<void> {
    const merchantRef = doc(this.firestore, "merchant", id);
    await updateDoc(merchantRef, data);
  }
  async getMerchant(id: string){
  const snap = await getDoc(doc(this.firestore, "merchant", id))
  if (snap.exists())
    return snap.data()
  else
    return Promise.reject(Error(`No such document: ${id}`))
  }
  ngOnInit() {
  }

  logout() {
    return signOut(this.auth);
  }

  // este es para los usuarios y contraseñas:

  async addUser(user: { nombre: string; entidad: string; usuario: string; contrasena: string }) {
    console.log('Agregando usuario:', user);
    const userRef = collection(this.firestore, 'usuarios');
    return addDoc(userRef, user);
  }

  getUsersPassword(): Observable<any[]> {
    return new Observable<any[]>((subscriber) => {
      const userRef = collection(this.firestore, 'usuarios');
      const unsubscribe = onSnapshot(userRef, (snapshot: QuerySnapshot<any>) => {
        const users: any[] = [];
        snapshot.docs.forEach((doc) => {
          users.push({ ...doc.data(), id: doc.id });
        });
        subscriber.next(users);
      }, (error) => {
        subscriber.error(error);
      });
  
      return () => unsubscribe();
    });
  }
  
  async updateUser(id: string, data: any): Promise<void> {
    const userRef = doc(this.firestore, "usuarios", id);
    await updateDoc(userRef, data);
  }
  
  async deleteUser(id: string) {
    await deleteDoc(doc(this.firestore, "usuarios", id));
  }
  
}