import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ServicesComponent } from '../../services/services.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  formLogin: FormGroup;

  constructor(
    private userService: ServicesComponent, private router: Router
) {
    this.formLogin = new FormGroup({
        email: new FormControl(),
        password: new FormControl()
    });
}


  ngOnInit(): void {
  }

  onSubmit() {
    this.userService.login(this.formLogin.value)
      .then(response => {
        console.log(response);
        this.modalSucess();
        this.router.navigate(['/list-merchant']); 
      })
      .catch(error => {
        console.log(error);
        this.modalError();
      });
  }
  

  modalError() {
    Swal.fire({
      title: 'Error!',
      text: 'Contraseña Errada.',
      icon: 'error',
      confirmButtonColor: '#60514c',
      iconColor: '#60514c',
    })
  }
  modalSucess() {
    Swal.fire({
      title: 'Logueo Exitoso!',
      text: 'Logueo!',
      icon: 'success',
      confirmButtonColor: '#60514c',
      iconColor: '#60514c',
    })
  }

}