<!--Main Navigation-->
<header>
  <!-- Sidebar -->
  <nav id="sidebarMenu" class="collapse d-lg-block sidebar collapse bg-white">
    <div class="position-sticky">
      <div class="list-group list-group-flush mx-3 mt-4">
       
        <a class="list-group-item list-group-item-action py-2 ripple active">
          <i class="fas fa-plus fa-fw me-3" (click)="onClick($event)"></i><span (click)="onClick($event)">Nuevo Usuario</span>
        </a>

        <a class="list-group-item list-group-item-action py-2 ripple" aria-current="true">
          <i class="fas fa-file-excel fa-fw me-3" (click)="exportToExcel()"></i><span (click)="exportToExcel()">Exportar a Excel</span>
        </a>
       

        <a class="list-group-item list-group-item-action py-2 ripple" aria-current="true">
          <i class="fa fa-key fa-fw me-3"></i><span >Aministración Contraseñas</span>
        </a>
        
        <a  class="list-group-item list-group-item-action py-2 ripple"><i
            class="fa fa-sign-out fa-fw me-3" (click)="onClickLogOut()"></i><span (click)="onClickLogOut()">Logout</span></a>
      </div>
    </div>
  </nav>
  <!-- Sidebar -->

  <!-- Navbar -->
  <nav id="main-navbar" class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
    <!-- Container wrapper -->
    <div class="container-fluid">
      <!-- Toggle button -->
      <button data-mdb-button-init class="navbar-toggler" type="button" data-mdb-collapse-init data-mdb-target="#sidebarMenu"
        aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
      </button>

      <!-- Brand -->
      <a class="navbar-brand" href="#">
        <img src="assets/img/logo.png" height="50" alt="Sobiem"
          loading="lazy" />
      </a>
            <!-- Search form -->
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-auto">
                <form class="d-none d-md-flex input-group w-auto my-auto buscador">
                  <span class="input-group-text border-0"><i class="fas fa-search"></i></span>
                  <div class="search-bar">
                    <input type="text" class="form-control rounded" placeholder="Buscar..." style="min-width: 225px;" (keyup)="searchTerm$.next($event.target.value)">
                  </div>                  
                </form>
              </div>
            </div>
          </div> 

      <!-- Right links -->
      <ul class="navbar-nav ms-auto d-flex flex-row">
        <!-- Icon -->
        <li class="nav-item">
          <a class="nav-link me-3 me-lg-0" href="#">
            <i class="fa fa-sign-out" (click)="onClickLogOut()"></i>
          </a>
        </li>
        <!-- Icon -->
        <li class="nav-item me-3 me-lg-0">
          <a class="nav-link" href="#">
            <i class="fas fa-file-excel" (click)="exportToExcel()"></i>
          </a>
        </li>

        <li class="nav-item me-3 me-lg-0">
          <a class="nav-link" href="#">
            <i class="fas fa-plus" (click)="onClick($event)"></i>
          </a>
        </li>
      </ul>
    </div>
    <!-- Container wrapper -->
  </nav>
  <!-- Navbar -->
</header>
<!--Main Navigation-->
<br><br>
<!--Main layout-->
<main style="margin-top: 58px;">
  <div class="container pt-4">
  
  <div class="table-responsive">    
    <table id="tabla-merchant" class="table table-striped table-hover table-sm">
      <thead class="thead-dark">
        <tr>
          <th>Acción</th>
          <th>Observaciones</th>
          <th>Resposable</th>
          <th>Interesado</th>
          <th>Ciudad</th>
          <th>Dirección</th>
          <th>Email</th>
          <th>Celular</th>
          <th>Nombre</th>
          <th>Documento</th>
          <th>Fecha Creación</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!searchTerm; else filteredData">
        <tr  *ngFor="let merchant of merchants">
          <td>
            <i class="fas fa-trash-alt fa-lg text-danger fa-fw me-3" (click)="deleteMerchant(merchant.id)"></i>
            <i class="fas fa-edit fa-lg text-info fa-fw me-3"  (click)="edit(merchant.id)"></i>
          </td>
          <td>{{ merchant.message }}</td>
          <td>{{ merchant.responsible }}</td>
          <td>{{ merchant.interested }}</td>
          <td>{{ merchant.city }}</td>
          <td>{{ merchant.address }}</td>
          <td>{{ merchant.email }}</td>
          <td>{{ merchant.phone }}</td>
          <td>{{ merchant.name }}</td>
          <td>{{ merchant.document }}</td>
          <td id="dateCreation">{{ merchant.dateCreation  }}</td>
        </tr>
      </ng-container>
      <ng-template #filteredData>
        <tr *ngFor="let merchant of listFiltered">
          <td>
            <i class="fas fa-trash-alt fa-lg text-danger fa-fw me-3" (click)="deleteMerchant(merchant.id)"></i>
            <i class="fas fa-edit fa-lg text-info fa-fw me-3"  (click)="edit(merchant.id)"></i>
          </td>
          <td>{{ merchant.message }}</td>
          <td>{{ merchant.responsible }}</td>
          <td>{{ merchant.interested }}</td>
          <td>{{ merchant.city }}</td>
          <td>{{ merchant.address }}</td>
          <td>{{ merchant.email }}</td>
          <td>{{ merchant.phone }}</td>
          <td>{{ merchant.name }}</td>
          <td>{{ merchant.document }}</td>
          <td id="dateCreation">{{ merchant.dateCreation }}</td>
        </tr>
      </ng-template>
      </tbody>
    </table>
  </div>

    <!-- The Modal -->
    <div class="modal" id="myModal" [style.display]="showModal ? 'block' : 'none'">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header --> 
          <div class="modal-header">
                <div class="newsletter">
                    <form class="newsletter-form row" [formGroup]="merchantForm" (ngSubmit)="handleSubmit()">
                      <div *ngIf="loading" class="spinner-border float-right" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <h4 class="modal-title">{{titulo}}</h4>
                        <div class="form-group col-md-6">
                          <div class="input-with-icon">
                            <input type="text" id="nombre" class="form-control" formControlName="document" placeholder="Documento">
                            <i class="fa fa-id-card" aria-hidden="true"></i>
                          </div>
                          </div>
                          <div class="form-group col-md-6">
                            <div class="input-with-icon">
                              <input type="text" id="nombre" class="form-control" formControlName="name" placeholder="Nombre" required>
                              <i class="fa fa-user" aria-hidden="true"></i>
                            </div>
                          </div>
                        <div class="form-group col-md-6">
                          <div class="input-with-icon">
                            <input type="tel" id="telefono" class="form-control" formControlName="phone" placeholder="Celular" required>
                            <i class="fa fa-phone-square" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <div class="input-with-icon">
                          <input type="email" class="form-control" formControlName="email" placeholder="Email" required>
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </div>
                        </div>
                        <div class="form-group col-md-6">
                          <div class="input-with-icon">
                          <input type="text" id="direccion" class="form-control" formControlName="address" placeholder="Dirección" required>
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </div>
                        </div>
                        <div class="form-group col-md-6">
                          <div class="input-with-icon">
                          <input type="text" id="ciudad" class="form-control" formControlName="city" placeholder="Ciudad" required>
                          <i class="fa fa-building" aria-hidden="true"></i>
                        </div>
                        </div>

                        <div class="form-row">
                          
                          <div class="form-group col d-flex align-items-center">
                            <i class="fas fa-star"></i>
                            <select id="interesado" class="form-control" required formControlName="interested">
                              <option value="" selected>Selecciona una opción</option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="TAL VEZ">TAL VEZ</option>
                            </select>
                            <label for="interesado" class="mr-6">:Interes </label>
                          </div>
                        </div>

                        <div class="form-row">
                          <div class="form-group col d-flex align-items-center">
                            <i class="fas fa-user-check"></i>
                            <select id="responsible" class="form-control" required formControlName="responsible">
                              <option value="" selected>Selecciona una opción</option>
                              <option value="Camila Alzate">Camila Alzate</option>
                              <option value="Yesica Marín">Yesica Marín</option>
                            </select>
                            <label for="responsible" class="mr-6">Responsable</label>
                          </div>
                        </div>
                        
                        
                        <div class="form-group col-md-12">
                          <div class="input-with-icon">
                          <textarea id="observaciones" class="form-control" rows="4" formControlName="message" placeholder="Tus observaciones"></textarea>
                          <i class="fa fa-commenting" aria-hidden="true"></i>
                        </div>
                        </div>
                        <div class="form-group col-md-12 d-flex justify-content-between">
                            <button class="btn btn-primary" type="submit">Guardar</button>
                            <button type="button" class="btn btn-danger" (click)="hide()">Close</button>
                          </div>
                      </form>
                      
                </div>      
        </div>
      </div>
    </div>
  </div>
  </div>
</main>
<!--Main layout-->