import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule } from '@angular/forms';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
// import { WelcomeComponent } from './components/common/welcome/welcome.component';
import { AboutComponent } from './components/common/about/about.component';
import { StrategyComponent } from './components/common/strategy/strategy.component';
import { WhoWeAreComponent } from './components/common/who-we-are/who-we-are.component';
import { CtaComponent } from './components/common/cta/cta.component';
import { SkillComponent } from './components/common/skill/skill.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { TeamComponent } from './components/common/team/team.component';
import { HowWeWorkComponent } from './components/common/how-we-work/how-we-work.component';
import { ServicesComponent } from './components/common/services/services.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { PricingComponent } from './components/common/pricing/pricing.component';
import { WorkComponent } from './components/common/work/work.component';
import { WhyWeDifferentComponent } from './components/common/why-we-different/why-we-different.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { DemoSidebarComponent } from './components/common/demo-sidebar/demo-sidebar.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { SigninComponent } from './components/common/authentication/signin/signin.component';
import { ListMerchantComponent } from './components/list-merchant/list-merchant.component';
import { PasswordManagerComponent } from './components/common/password-manager/password-manager.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { ModalUsuariosComponent } from './components/modal-usuarios/modal-usuarios.component';



@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HomeOneComponent,
        NavbarComponent,
        // WelcomeComponent,
        AboutComponent,
        StrategyComponent,
        WhoWeAreComponent,
        CtaComponent,
        SkillComponent,
        FunfactsComponent,
        TeamComponent,
        HowWeWorkComponent,
        ServicesComponent,
        FooterComponent,
        PartnerComponent,
        BlogComponent,
        FeedbackComponent,
        FaqComponent,
        PricingComponent,
        WorkComponent,
        WhyWeDifferentComponent,
        ContactComponent,
        BlogDetailsComponent,
        DemoSidebarComponent,
        SigninComponent,
        ListMerchantComponent,
        ModalUsuariosComponent,
        PasswordManagerComponent,
        SidebarComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxScrollTopModule,
        CarouselModule,
        FormsModule,
 
        NgxTypedJsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ToastrModule.forRoot()
        
        
    ],
    providers: [ 
        ServicesComponent,
        provideFirebaseApp(() => initializeApp({"projectId":"sobiem","appId":"1:276659817460:web:0ed0ae8b4dc5807d0c07a1","storageBucket":"sobiem.appspot.com","apiKey":"AIzaSyBqHsCQKJ_GLmA5LykhjDWbfK0gnlets3Q","authDomain":"sobiem.firebaseapp.com","messagingSenderId":"276659817460","measurementId":"G-QW7EQY9DZZ"})),
        provideFirestore(() => getFirestore()),
        provideAuth(() => getAuth())

  ],
    bootstrap: [AppComponent]
})
export class AppModule { }