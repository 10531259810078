import { Component, ViewChild } from '@angular/core';
import { ModalUsuariosComponent } from '../../modal-usuarios/modal-usuarios.component';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  showModal: boolean = false;
  @ViewChild(ModalUsuariosComponent) modalUsuarios!: ModalUsuariosComponent;

  abrirModalUsuarios() {
    this.modalUsuarios.mostrarModal();
  }
}
