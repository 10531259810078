    <div id="faq" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Como <span>Trabajamos</span></h2>
            <p>Nuestro compromiso es proporcionar servicios integrales que promuevan el bienestar laboral,
                 aseguren la seguridad social y garanticen la salud y seguridad en el trabajo 
                 Nuestro enfoque se basa en un proceso meticuloso y colaborativo diseñado para satisfacer las 
                 necesidades específicas de cada organización</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <div class="accordion">
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(0)" [ngClass]="{ 'open': isSectionOpen(0) }">
                                Evaluación y Diagnóstico Integral
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                                <p><b>Análisis Inicial:</b> Realizamos una contextualización exhaustiva de la empresa, 
                                    evaluando las necesidades específicas para los empleados y la organización en
                                     bienestar laboral, seguridad social y seguridad y salud en el trabajo
                                    </p><br>
                                <p><b>Análisis Inicial:</b> Diagnóstico Personalizado: Realizamos Asesoría y reunión empresarial para 
                                    obtener una visión clara y detallada de la situación actual de los empleados y 
                                    la organización
                                </p>
                                    
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(1)" [ngClass]="{ 'open': isSectionOpen(1) }">
                                Diseño de Soluciones Personalizadas
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                                <p><b>Gestión de Seguridad Social:</b>Asesoramos y gestionamos los procesos relacionados 
                                    con la seguridad social, asegurando que todas las obligaciones legales se cumplan y que los empleados
                                     reciban el apoyo y los beneficios a los que tienen derecho
                                    </p><br>
                                
                                <p><b>Programas de Bienestar Laboral:</b> Desarrollamos programas adaptados que incluyen actividades físicas,
                                     talleres de mindfulness, asesoramiento nutricional y apoyo emocional para promover un entorno de trabajo 
                                     saludable y motivador
                                </p><br>

                                <p><b>Planes de Seguridad y Salud en el Trabajo:</b> Creamos planes detallados de seguridad y salud que cumplen 
                                    con las normativas legales y se adaptan a las características específicas de cada empresa, 
                                    incluyendo sistema de gestión (SGSST), formación en prevención de riesgos laborales, simulacros de emergencia y auditorías
                                     regulares
                               </p><br>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(2)" [ngClass]="{ 'open': isSectionOpen(2) }">
                                Implementación Eficaz
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                                <p><b>Planificación Detallada:</b> Elaboramos un plan de implementación claro y preciso para asegurar
                                    una ejecución efectiva y sin contratiempos de todas nuestras iniciativas y programas.
                                    Capacitación y Formación: Ofrecemos sesiones de formación y capacitación para empleados y
                                    directivos, asegurando que todos comprendan y se adhieran a las políticas y prácticas de seguridad
                                    y bienestar
                               </p><br>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(3)" [ngClass]="{ 'open': isSectionOpen(3) }">
                                Monitoreo y Evaluación Continua
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                                <p><b>Seguimiento Proactivo:</b> Monitorizamos de forma continua la efectividad de
                                     nuestras iniciativas a través acompañamiento estratégico presencial y virtual empresarial.
                               </p><br>
                               <p><b>Ajustes y Mejoras:</b> Basándonos en los datos recopilados, realizamos ajustes y mejoras continuas para asegurar
                                 que nuestras soluciones sigan siendo efectivas y relevantes
                                </p><br>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(4)" [ngClass]="{ 'open': isSectionOpen(4) }">
                                Colaboración y Comunicación Abierta
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                                <p><b>Trabajo en Equipo:</b> Colaboramos estrechamente con los líderes de la empresa y los departamentos relevantes
                                     para asegurar que nuestras iniciativas se integren de manera coherente con la cultura organizacional y los
                                     objetivos empresariales
                                </p><br>
                                <p><b>Comunicación Transparente:</b> Mantenemos una comunicación abierta y fluida con todos los clientes,
                                     informando regularmente sobre el progreso y los resultados de nuestras actividades
                               </p><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>