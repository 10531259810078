<section class="vh-100" style="background-color: #528e7d;">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col col-xl-10">
          <div class="card" style="border-radius: 1rem;">
            <div class="row g-0">
              <div class="col-md-6 col-lg-5 d-none d-md-block">
                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                  alt="login form" class="img-fluid" style="border-radius: 1rem 0 0 1rem;" />
              </div>
              <div class="col-md-6 col-lg-7 d-flex align-items-center">
                <div class="card-body p-4 p-lg-5 text-black">
                <form [formGroup]="formLogin" (ngSubmit)="onSubmit()">
                    <div class="d-flex align-items-center mb-3 pb-1">
                      <i  class="fas fa-cubes fa-2x me-3" style="color: #528e7d;"></i>
                      <h3><a class="h1 fw-bold mb-0" href="/home">Sobiem</a></h3>
                    </div>
                    <div data-mdb-input-init class="form-outline mb-4">
                      <input type="email" formControlName="email"  class="form-control form-control-lg" />
                      <label class="form-label"  >Email</label>
                    </div>
  
                    <div data-mdb-input-init class="form-outline mb-4">
                      <input type="password" formControlName="password"  class="form-control form-control-lg" />
                      <label class="form-label">Contraseña</label>
                    </div>
  
                    <div class="pt-1 mb-4">
                      <button data-mdb-button-init data-mdb-ripple-init class="btn btn-dark btn-lg btn-block" type="submit">Login</button>
                    </div>
                  </form>
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>