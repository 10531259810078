import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ServicesComponent } from '../common/services/services.component';
import { emailValidator } from '../common/utils/app-validators';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ModalUsuariosComponent } from '../modal-usuarios/modal-usuarios.component';


@Component({
  selector: 'app-list-merchant',
  templateUrl: './list-merchant.component.html',
  styleUrl: './list-merchant.component.scss',
})


export class ListMerchantComponent {
  @ViewChild(ModalUsuariosComponent) modalUsuarios!: ModalUsuariosComponent; 
  showModal : boolean;
  merchantForm: FormGroup;
  currentDate: string;
  loading: boolean = false;
  merchants: any[] = [];
  titulo:string = 'Agregar Usuario';
  merchantData: any;
  flag: boolean = true;
  _merchantId:string;
  nameXLSX = 'Merchant-Sobiem-' + new Date().toISOString().slice(0, 10) + '.xlsx';
  listFiltered = [];
  searchTerm$ = new Subject<string>();
  searchTerm: string = '';

  constructor(private merchantService: ServicesComponent, private aRoute: ActivatedRoute, private router: Router) {
    this.merchantForm = new FormGroup({
      document: new FormControl(''),
      name: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, emailValidator]),
      address: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      interested: new FormControl('', Validators.required),
      responsible: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      dateCreation: new FormControl(new Date()),
      dateUpdate: new FormControl(new Date())
  });
      // Inicializa la suscripción al término de búsqueda
      this.searchTerm$
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(term => {
        this.searchTerm = term;
        this.filterList();
      });
  
}


ngOnInit(): void {
  this.currentDate = this.getCurrentDateFormatted();
  this.getMerchant();
  this.listFiltered = this.merchants;
  this.filterList();
}
getCurrentDateFormatted(): string {
  const date = new Date();
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}


modalError() {
  Swal.fire({
    title: 'Error!',
    text: 'Hubo un problema al guardar los datos.',
    icon: 'error',
    confirmButtonColor: '#60514c',
    iconColor: '#60514c',
  })
}
modalSucess() {
  Swal.fire({
    title: 'Buen trabajo!',
    text: 'Datos Guardados con éxito!',
    icon: 'success',
    confirmButtonColor: '#60514c',
    iconColor: '#60514c',
  })
}
modalUpdate() {
  Swal.fire({
    title: 'Buen trabajo!',
    text: 'Datos Actualizados con éxito!',
    icon: 'success',
    confirmButtonColor: '#60514c',
    iconColor: '#60514c',
  })
}

modalWarning() {
  Swal.fire({
    title: 'Ojo el registro ya existe!',
    text: 'Datos No Guardados!',
    icon: 'warning',
    confirmButtonColor: '#60514c',
    iconColor: '#60514c',
  })
}

modalWarningform() {
  Swal.fire({
    title: 'Faltan datos en el formulario',
    text: 'Datos No Guardados!',
    icon: 'warning',
    confirmButtonColor: '#60514c',
    iconColor: '#60514c',
  })
}

modalDrop(merchantId:string) {
  Swal.fire({
    title: "?Estas Segura",
    text: "!Si lo eliminas no lo puedes recuperar",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "!Si, Eliminar"
  }).then((result) => {
    if (result.isConfirmed) {
      this.merchants = this.merchants.filter(merchant => merchant.id !== merchantId);
      this.merchantService.dropMerchant(merchantId);
      Swal.fire({
        title: "Eliminar!",
        text: "Registro Eliminado.",
        icon: "success"
      });

    }
  });
}

  onClick(event)
  {
    console.log(event)
      this.showModal = true; // Show-Hide Modal Check
  }
  //Bootstrap Modal Close event
  hide()
  {
    this.showModal = false;
    this.titulo = 'Agregar Usuario';
    this.merchantForm.reset();
  }

  getMerchant() {
    this.merchantService.getMerchants().subscribe(
      (listMerchant: any[]) => {
        this.merchants = listMerchant.map((dic) => ({
          message: dic.message ,
          interested: dic.interested ,
          responsible: dic.responsible ,
          city: dic.city ,
          address: dic.address ,
          email: dic.email ,
          phone: dic.phone ,
          name: dic.name ,
          document: dic.document ,
          id: dic.id ,
          dateCreation: this.formatDate(dic.dateCreation),
        }));
      },
      (error) => {
        console.error('Error fetching merchants:', error);
      }
    );
  }

  formatDate(timestamp: { seconds: number, nanoseconds: number }): string {
    if (!timestamp) {
      return '';
    }
  
    // Convertir el timestamp a milisegundos y crear una instancia de Date
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  
    // Formatear la fecha según el formato deseado
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Añadir cero al mes si es necesario
    const day = ('0' + date.getDate()).slice(-2); // Añadir cero al día si es necesario
  
    return `${year}-${month}-${day}`; // Formato: yyyy-mm-dd
  }

  deleteMerchant(merchantId: string) {
  this.modalDrop(merchantId);  
  }


  
  handleSubmit() {
    if (this.flag) {
      this.titulo = 'Agregar Usuario';
      this.onSubmit();
    } else {
      this.updateMerchant();
    }
  }

  async onSubmit() {
    this.loading = true;
    if (this.merchantForm.valid){
    const email_merchant = this.merchantService.getEmailMerchant(this.merchantForm.get('email').value)
    if ((await email_merchant).length === 0) {
      try {
        await this.merchantService.addMerchant(this.merchantForm.value);
        this.modalSucess();
        this.merchantForm.reset();
        this.hide();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.modalError()
        
      }
    } else {
      const _email: string = (await email_merchant).join(', ');
      this.modalWarning()
      this.loading = false;
    }
    }else{
      this.modalWarningform();
      this.loading = false;
    }
  }
 

  async edit(merchantId: string) {
    this.titulo = 'Modificar Usuario';
    this.flag = false;
    this._merchantId = merchantId;
    this.showModal = true;
    try {
      this.merchantData = await this.merchantService.getMerchant(merchantId);
      console.log('mensaje ' + this.merchantData.message);
      this.merchantForm.setValue({
        message: this.merchantData.message,
        interested: this.merchantData.interested,
        responsible: this.merchantData.responsible,
        city: this.merchantData.city,
        address: this.merchantData.address,
        email: this.merchantData.email,
        phone: this.merchantData.phone,
        name: this.merchantData.name,
        document: this.merchantData.document,
        dateCreation: this.merchantData.dateCreation,
        dateUpdate: new Date()
      });
    } catch (error) {
      console.error('Error al obtener el comerciante:', error);
    }
    // console.log('este es el resultado inicial ' + JSON.stringify(this.merchantForm.value));
  }

    updateMerchant(){
      this.loading = true;
      this.merchantService.updateMerchant(this._merchantId, (this.merchantForm.value)).then(() => {
      this.loading = false;
      this.modalUpdate(); 
      this.flag = true;
      this.merchantForm.reset();
      this.showModal = false;
      this.titulo = 'Agregar Usuario';
    }).catch(error => {
      this.loading = false;
      console.error('Error actualizando el comerciante:', error);
    });

    }

    onClickLogOut() {
      this.merchantService.logout()
        .then(() => {
          this.router.navigate(['/signin']);
        })
        .catch(error => console.log(error));
    }

    
    exportToExcel(): void {
      let element = document.getElementById('tabla-merchant');
      
      if (!element) {
        Swal.fire({
          title: 'Error',
          text: 'No se encontró la tabla para exportar.',
          icon: 'error',
          confirmButtonColor: '#60514c'
        });
        return;
      }
    
      const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
      const book: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(book, worksheet, 'Datos');
    
      try {
        XLSX.writeFile(book, this.nameXLSX);
        Swal.fire({
          title: '¡Exportación Exitosa!',
          text: 'El archivo Excel se ha exportado correctamente.',
          icon: 'success',
          confirmButtonColor: '#60514c'
        });
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Hubo un problema al exportar el archivo.',
          icon: 'error',
          confirmButtonColor: '#60514c'
        });
      }
    }
    

    filterList(): void {
      this.searchTerm$.subscribe(term => {
        if (term.trim() === '') {
          this.listFiltered = this.merchants; 
        } else {
          this.listFiltered = this.merchants.filter(item =>
            item.name.toLowerCase().includes(term.toLowerCase()) ||
            item.document.toLowerCase().includes(term.toLowerCase()) ||
            item.phone.toLowerCase().includes(term.toLowerCase()) ||
            item.email.toLowerCase().includes(term.toLowerCase())
          );
        }
      });
    }

    emitSearchTerm(value: string): void {
      this.searchTerm$.next(value);
    }

    confirmarEnvioCorreos() {
      Swal.fire({
        title: '?Estás seguro¿',
        text: '?Quieres enviar los correos masivos¿',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#60514c',
        iconColor: '#60514c',
      }).then((result) => {
        if (result.isConfirmed) {
          this.verificarMacroEjecutada();
        }
      });
    }
    
    verificarMacroEjecutada() {
      Swal.fire({
        title: '?Ejecutaste la macro¿',
        text: 'Confirma que ya ejecutaste la macro antes de enviar.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#60514c',
        iconColor: '#60514c',
      }).then((result) => {
        if (result.isConfirmed) {
          this.verificarArchivoSubido();
        }
      });
    }
    
    verificarArchivoSubido() {
      Swal.fire({
        title: '?Subiste el archivo¿',
        text: 'Asegúrate de que el archivo CSV está en Firebase Storage.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sí, enviar correos',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#60514c',
        iconColor: '#60514c',
      }).then((result) => {
        if (result.isConfirmed) {
          this.enviarCorreos();
        }
      });
    }
    
    enviarCorreos() {
      Swal.fire({
        title: 'Enviando correos...',
        text: 'Por favor, espera mientras se procesan los correos.',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    
      fetch("https://email-masivos-v5pcfjzzwq-uc.a.run.app", {
        method: "GET",
      })
      .then(response => response.json())  // Convertimos la respuesta a JSON
      .then(data => {
        console.log("🔹 Datos recibidos:", data);
        if (data.status === "success") {
          Swal.fire({
            title: '¡Correos enviados!',
            text: data.message,
            icon: 'success',
            confirmButtonColor: '#60514c',
            iconColor: '#60514c',
          });
        } else {
          throw new Error(data.message || "Error desconocido"); // Forzamos el catch si no es success
        }
      })
    }
    
    modalPendiente() {
      Swal.fire({
        title: 'Funcionalidad en Construcción',
        text: 'Pendiente que Jessica explique qué quiere exactamente.',
        icon: 'info',
        confirmButtonColor: '#60514c'
      });
    }
    
    abrirModalUsuarios() {
      this.modalUsuarios.mostrarModal();  // ⬅️ Llama la función del modal
    }
    
    
      }

 




